import React from "react";
import "./About.css";
import aboutusimg from "../../assests/christina-wocintechchat-com-34GZCgaVksk-unsplash.jpg";
import ourstory from "../../assests/ourstory.png";
import ourvision from "../../assests/ourvision.png";
import ourtech from "../../assests/ourtech.png";
// new
const AboutUs = () => {
  return (
    <div className="about-us">
      <h1 className="head">ABOUT US</h1>

      <div className="herosection">
        <img src={aboutusimg} alt="" />
        <div className="point">
          <p>Improving Everyday life With Software Services </p>
        </div>
      </div>

      <section className="our-story">
        <div className="line"></div>

        <h2>Our Story</h2>

        <h1 className="storyline">
          "Empowering the Future: Our Journey of Innovation and Excellence"
        </h1>
        <div className="story">
          <img src={ourstory} alt="" />
          <p>
            Founded in 2016 as Exousia Software Services, our company built a
            strong foundation in delivering high-quality software solutions and
            consulting services. Over the years, we gained a reputation for our
            commitment to innovation, client satisfaction, and excellence in
            software development and real-time analytics.
          </p>
          <br />
          <p>
            In 2024, we undertook a significant transformation and rebranded as
            Ecuity Exousia IT and ITES Private Limited. “Ecuity” symbolizes
            equity and fairness, reinforcing our commitment to delivering
            balanced and high-quality solutions that meet diverse client needs.
            It conveys our promise to bring both innovation and reliability to
            the table, enhancing our overall market presence. This strategic
            rebranding reflects our continued commitment to advancing our
            service offerings and aligning with the evolving needs of our
            clients. As Ecuity Exousia, we’ve expanded our focus to incorporate
            cutting-edge technologies and innovative solutions, positioning
            ourselves at the forefront of the IT and ITES (Information
            Technology Enabled Services) industries.
          </p>
          <br />
          <p>
            Our rebranded identity signifies not only a new name but also a
            renewed dedication to providing exceptional, tailored solutions. Our
            team, which consists of seasoned professionals with decades of
            combined experience, is equipped to handle a wide range of
            challenges and deliver solutions that drive business success. From
            advanced software development and strategic consulting to
            sophisticated real-time analytics, we are dedicated to helping
            businesses of all sizes achieve their goals with precision and
            efficiency.
          </p>
          <br />
          <p>
            At Ecuity Exousia, we understand that the technology landscape is
            constantly evolving, and we are committed to staying ahead of the
            curve to offer our clients the most relevant and effective
            solutions. Our legacy of excellence continues to guide us as we
            embrace new opportunities and drive innovation in the IT
            and ITES sectors.
          </p>
        </div>
      </section>

      <section className="our-vision">
        <div className="line"></div>
        <h2>Our Vision</h2>
        <h1 className="storyline">
          "Shaping Tomorrow: Our Vision for a Brighter, Connected Future"
        </h1>
        <div className="vision">
          <img src={ourvision} alt="" />
          <p>
            At Ecuity Exousia Software Services, our vision is to be the go-to
            software provider for businesses across industries. We strive to
            provide innovative solutions that are tailored to meet the diverse
            needs of our clients.
          </p>
        </div>
      </section>

      <section className="our-technology">
        <div className="line"></div>
        <h2>Our Technology</h2>
        <h1 className="storyline">
          "Cutting-Edge Solutions: The Technology Powering Our Success"
        </h1>
        <div className="story">
          <img src={ourtech} alt="" />
          <p>
            We leverage cutting-edge technology to provide our clients with the
            best possible solutions. Our team of experts stays up-to-date with
            the latest trends in software development, consulting and real-time
            analytics to provide innovative and bespoke solutions.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
